<template>
  <el-dialog
      :visible.sync="dialogVisible"
      width="429px">
    <img class="icon" src="@/assets/images/icon_cg@2x.png" alt="">
    <h1>{{ type }}</h1>
    <p v-if="type=='意见反馈'">提交后弹出提示页面:您的意见反馈已提交成功，工作人员会第一时间电话给您联系。</p>
    <p v-if="type=='投诉建议'">提交后弹出提示页面:您的投诉建议已提交成功，审监委将会通过电话联系给您联系。</p>
    <div class="btn-group">
      <div class="btn" @click="dialogVisible=false">取消</div>
      <div class="btn" @click="dialogVisible=false">确认</div>
    </div>

  </el-dialog>
</template>

<script>
export default {
  name: "ReminderDialog",
  data() {
    return {
      dialogVisible: false
    };
  },
  components: {},
  props: ["type"],
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__body{
  .icon{
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto;
  }
  h1{    margin-top: 10px;

    font-size: 18px;
    font-weight: 500;
    color: #333333;
    text-align: center;
  }
  p{
    width: 314px;
    margin: 20px auto 0;
    font-size: 14px;
    color: #333333;
    line-height: 30px;
  }
  .btn-group{
    margin: 30px auto;
    width: 208px;
    overflow: hidden;
    .btn{
      cursor: pointer;
      width: 66px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      &:first-child{
        float: left;
        background-color: #DADADA;
        color: black;
      }
      &:nth-child(2){
        float: right;
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
</style>